import React, { useEffect, useState, useRef } from "react";
import { Loader } from "react-loaders";

let ZipCodes = {};
let MSL = {};
let CurrentZip = undefined;
let isWhitespace = false;

// MT00000

const MSLLookup = () => {
	const autoCompleteRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState("");
	const [inputState, setInputState] = useState("");
	const [zipError, setZipError] = useState(false);
	const [invalidZip, setInvalidZip] = useState(null);
	const [repData, setRepData] = useState(null);

	const images = require.context('../images/msl_headshots', true);

	useEffect(
		() => {
			async function loadMSL() {
				const res = await fetch('json/msl_data.json');
				const json = await res.json();
				MSL = json;
				//console.log("MSL: " + JSON.stringify(MSL));
			}
			loadMSL();
		}, [value]
	);

	useEffect(
		() => {
			async function loadZips() {
				if (Object.keys(ZipCodes).length === 0) {
					const res = await fetch('json/ztt_out.json');
					const json = await res.json();
					ZipCodes = json;
					console.log("ZipCodes Loaded!");
					//console.log("ZipCodes: " + JSON.stringify(ZipCodes));
				} else {
					console.log("ZipCodes Already Loaded!");
				}
			}
			loadZips();
		}, [value]
	);

	async function handleLocationSubmit() {
		//.length === 5

		if (CurrentZip !== undefined) {
			if (CurrentZip.length !== 5) {
				setZipError(true);
				setInvalidZip(null);
				return;
			} else {
				setZipError(false);
				// valid?
				if (ZipCodes.hasOwnProperty(CurrentZip.toString())) {
					setInvalidZip(null);
				} else {
					setInvalidZip(CurrentZip);
					return;
				}
			}
		} else {
			setZipError(true);
			setInvalidZip(null);
			return;
		}

		// show loader

		const rep = await getMSL(CurrentZip)[0];

		rep.zip = CurrentZip;

		//console.log("Found MSL: " + JSON.stringify(rep));

		setRepData(rep);
		setIsLoading(false);
	}

	function getMSL(zip) {
		var terr;
		var curMSL = undefined;
		var terr_num = ZipCodes[CurrentZip];
		if (terr_num === "9") {
			isWhitespace = true;
			terr = "MT999999";
		} else {
			isWhitespace = false;
			terr = "MT00000" + terr_num;
		}

		if (MSL.hasOwnProperty(terr)) {
			curMSL = MSL[terr];
			return curMSL;
		} else {
			console.log("MSL does not have terr: " + terr);
		}
	}

	function checkZipCode(val) {
		const re = /^[0-9\b]+$/;
		if (val === '' || re.test(val)) {
			setInputState(val);
			CurrentZip = val;
			//console.log("Current ZIP: " + CurrentZip);
			if (val.length === 5) {
				setZipError(false);
			}
		} else {
			setInputState(val);
		}
	}


	function getFormattedPhone() {
		if (repData.Phone !== "No") {
			//var regex = /\D/g;
			//var updatedPhone = repData.Phone.replace(regex, "");
			//
			var cleaned = (repData.Phone).replace(/\D/g, '');
			var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
			if (match) {
				return '+1 (' + match[1] + ') ' + match[2] + '-' + match[3];
			}
		} else {
			return "";
		}
	}

	function getHeadshot() {
		//console.log("Rep Data: " + JSON.stringify(repData));
		var name = repData.Name;
		//console.log("Rep Name: " + name);
		var img = name.toLowerCase();
		img = img.replace(" ", "_");
		img = img.replace(" (interim)", "");
		if (repData.Headshot === "Yes") {
			try {
				var dynamicImage = images(`./${img}.jpg`);
				//console.log("MSL Photo: " + dynamicImage);
				return dynamicImage;
			}
			catch (err) {
				return false;
			}
		}
	}

	return (
		<div className="section section-half msl-lookup-wrapper">
			{!isLoading && !repData && (
				<>
					<div className="row msl_mol">
						<div className="col-sm-12">
							<h5>
								Please enter your zip code to find an Akebia field medical expert near you.
							</h5>
							<div className="msl-lookup-zip">
								<label htmlFor="zipcode">Enter your zip code</label>
								{/* update this api key to a production one!!!!! */}
								<div className={`search-location-input ${zipError ? "zip-error" : ""} ${invalidZip ? "zip-error" : ""}`}>
									<input
										ref={autoCompleteRef}
										onChange={event => checkZipCode(event.target.value)}
										placeholder="Enter your zip code"
										value={inputState}
									/>
									{zipError && (
										<p>Please enter a valid 5-digit zip code</p>
									)}
									{invalidZip && (
										<p>{invalidZip} is not a valid zip code</p>
									)}
								</div>
								<div
									tabIndex={0}
									className={`checkbox ${isChecked ? "checked" : ""}`}
									onKeyPress={e => setIsChecked(!isChecked)}
									onClick={e => setIsChecked(!isChecked)}
									role="checkbox"
									aria-checked={isChecked}
								>
									<p>
										I confirm that I am a healthcare professional practicing in the
										U.S.
									</p>
								</div>
								<button
									className="btn btn-primary"
									onClick={handleLocationSubmit}
									disabled={!isChecked}
								>
									GO
								</button>
							</div>
						</div>
					</div>
				</>
			)}
			{isLoading && (
				<div className="msl-lookup-zip">
					<Loader type="ball-beat" active />
				</div>
			)}
			{repData && isWhitespace && (
				<>
					{/*<pre>{JSON.stringify(repData, null, 4)}</pre>*/}
					<h3>
						Based on the closest zip code: <u>{repData.zip}</u>{" "}
						{/* eslint-disable */}
						<a
							onClick={() => {
								setRepData(null);
							}}
							className="change-zip"
						>
							Change
						</a>
						{/* eslint-enable */}
					</h3>
					<div className="msl_container msl-whitespace">
						<div className="col">
							<div className="msl-lookup-zip msl-whitespace">
								<p className="msl-name">
									Please email <a href="mailto:medicalinfo@akebia.com" title="Email us for more information">medicalinfo@akebia.com</a> with your interest in connecting with a Field Medical Expert and our team will contact you shortly.
								</p>
							</div>
						</div>
					</div>
				</>
			)}
			{repData && !isWhitespace && (
				<>
					{/*<pre>{JSON.stringify(repData, null, 4)}</pre>*/}
					<h3>
						Based on the closest zip code: <u>{repData.zip}</u>{" "}
						{/* eslint-disable */}
						<a
							onClick={() => {
								setRepData(null);
							}}
							className="change-zip"
						>
							Change
						</a>
						{/* eslint-enable */}
					</h3>
					<div className="msl_container">
						{getHeadshot() && (
							<div className="col-sm-auto">
								<div className="msl-lookup-zip">
									<img className="msl-headshot" src={`${getHeadshot()}`} alt="MSL Headshot" />
								</div>
							</div>
						)}
						<div className="col">
							<div className="msl-lookup-zip">
								<p className="msl-name">
									<strong>
										{repData.Name}, {repData.Degree}
									</strong>
								</p>
								<p>
									{repData.Title}
									<br />
									<a href={`mailto:${repData.Email}`}>
										{repData.Email}
									</a>
									<br />
									<a href={`tel:${getFormattedPhone()}`}>
										{getFormattedPhone()}
									</a>
								</p>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default MSLLookup;
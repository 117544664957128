import React, { useState } from "react";
import axios from "axios";
import qs from "qs";

const ContactForm = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [title, setTitle] = useState("");
	const [degreeMD, setDegreeMD] = useState(false);
	const [degreeDO, setDegreeDO] = useState(false);
	const [degreeRD, setDegreeRD] = useState(false);
	const [degreeNP, setDegreeNP] = useState(false);
	const [degreeRN, setDegreeRN] = useState(false);
	const [degreePharmD, setDegreePharmD] = useState(false);
	const [degreeRPh, setDegreeRPh] = useState(false);
	const [degreeOther, setDegreeOther] = useState(false);
	const [degreeOtherValue, setDegreeOtherValue] = useState("");
	const [office, setOffice] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [zipLabel, setZipLabel] = useState("Zip*");
	const [zipRequired, setZipRequired] = useState("column form_element_required");
	const [country, setCountry] = useState("United States");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [comments, setComments] = useState("");
	const [productAuryxia, setProductAuryxia] = useState(false);
	const [productVadadustat, setProductVadadustat] = useState(false);
	const [productOther, setProductOther] = useState(false);
	const [productOtherValue, setProductOtherValue] = useState("");
	const [deliveryEmail, setDeliveryEmail] = useState(false);
	const [deliveryFax, setDeliveryFax] = useState(false);
	const [deliveryMail, setDeliveryMail] = useState(false);
	const [deliveryPhone, setDeliveryPhone] = useState(false);
	const [deliveryMSL, setDeliveryMSL] = useState(false);
	const [attest, setAttest] = useState(false);
	const [privacy, setPrivacy] = useState(false);
	const [didSubmit, setDidSubmit] = useState(false);

	function validateFormText(text, minLength, maxLength) {
		if (text.length < minLength) {
			return false;
		} else {
			if (maxLength) {
				if (text.length > maxLength) {
					return false;
				}
			}
			return text;
		}
	}

	function validateEmail(email) {
		// eslint-disable-next-line
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(email).toLowerCase())) {
			return email;
		} else {
			return false;
		}
	}

	function validateDegree() {
		var list = document.querySelectorAll("#form_degree .checkbox.checked");
		if (list.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	function validateProduct() {
		var list = document.querySelectorAll("#form_product .checkbox.checked");
		if (list.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	function validateZip() {
		var container = document.getElementById('zip_required_field');
		var field = document.getElementById('form_zip');
		if (container.className.includes('form_element_required')) {
			return validateFormText(field.value, 5, 5);
		} else {
			return true;
		}
	}

	function checkCountryZip(e) {
		var c = e.target.value;
		//console.log("Country: " + c);
		if (c !== 'United States' && c !== 'US' && c !== 'USA') {
			setZipLabel("Zip");
			setZipRequired("column");
		} else {
			setZipLabel("Zip*");
			setZipRequired("column form_element_required");
		}
		setCountry(c);
	}

	function getCheckBoxes(section) {
		var output = [];
		if (section === "degree") {
			if (degreeMD) output.push("MD");
			if (degreeDO) output.push("DO");
			if (degreeRD) output.push("RD");
			if (degreeNP) output.push("NP");
			if (degreeRN) output.push("RN");
			if (degreePharmD) output.push("PharmD");
			if (degreeRPh) output.push("RPh");
			if (degreeOther) output.push("Other: " + degreeOtherValue);
		} else if (section === "product") {
			if (productAuryxia) output.push("Auryxia");
			if (productVadadustat) output.push("Vadadustat");
			if (productOther) output.push("Other: " + productOtherValue);
		} else if (section === "delivery") {
			if (deliveryEmail) output.push("Email");
			if (deliveryFax) output.push("Fax");
			if (deliveryMail) output.push("Mail");
			if (deliveryPhone) output.push("Phone");
			if (deliveryMSL) output.push("FieldMedicalExpert");
		}
		return output.join(", ");
	}

	function setFormError(id, otherBlank) {
		var el = document.getElementById(id);
		var err = el.getAttribute("data-error");
		if (otherBlank) {
			console.log("Other is blank!");
			if (id === 'form_degree_error') {
				err = 'Degree information is required';
			} else if (id === 'form_product_error') {
				err = 'Other topic is required';
			} else {
				err = 'Please fill in Other';
			}
		}
		if (!el.className.includes("form_error")) {
			el.classList.add("form_error");
			el.insertAdjacentHTML(
				"afterend",
				'<p class="form_error">' + err + "</p>"
			);
		}
	}

	function removeErrors() {
		var errors = document.querySelectorAll("p.form_error");
		[].forEach.call(errors, function (error) {
			error.remove();
		});
		var elems = document.querySelectorAll(".form_error");
		[].forEach.call(elems, function (el) {
			el.classList.remove("form_error");
		});
	}

	function validateRequestForm() {
		var isValid = true;

		if (!validateFormText(firstName, 2)) {
			setFormError("form_first_name");
			isValid = false;
		}
		if (!validateFormText(lastName, 2)) {
			setFormError("form_last_name");
			isValid = false;
		}

		if (!validateFormText(office, 4)) {
			setFormError("form_office");
			isValid = false;
		}

		if (!validateDegree()) {
			setFormError("form_degree_error");
			isValid = false;
		} else {
			if (degreeOther && degreeOtherValue === "") {
				setFormError("form_degree_error", true);
				isValid = false;
			}
		}

		if (!validateProduct()) {
			setFormError("form_product_error");
			isValid = false;
		} else {
			if (productOther && productOtherValue === "") {
				setFormError("form_product_error", true);
				isValid = false;
			}
		}

		if (!validateFormText(phone, 10)) {
			setFormError("form_phone");
			isValid = false;
		}
		if (!validateEmail(email)) {
			setFormError("form_email");
			isValid = false;
		}
		if (!validateFormText(comments, 10)) {
			setFormError("form_comments");
			isValid = false;
		}
		if (!validateFormText(country, 4)) {
			setFormError("form_country");
			isValid = false;
		}
		if (!validateZip()) {
			setFormError("form_zip");
			isValid = false;
		}
		if (!attest) {
			setFormError("form_attest");
			isValid = false;
		}
		if (!privacy) {
			setFormError("form_privacy");
			isValid = false;
		}

		return isValid;
	}

	function processSend() {
		var formData = {};
		formData["First Name"] = firstName;
		formData["Last Name"] = lastName;
		formData.Title = title;
		formData.Degree = getCheckBoxes("degree");
		formData.Institution = office;
		formData.Address = address;
		formData.City = city;
		formData.State = state;
		formData.Zip = zip;
		formData.Country = country;
		formData.Phone = phone;
		formData.Email = email;
		formData.Questions = comments;
		formData.Product = getCheckBoxes("product");
		formData["Delivery Method"] = getCheckBoxes("delivery");
		formData.Attestation = attest;
		formData.PrivacyAttestation = privacy;
		formData.__subject = "AkebiaMedical.com MIRF Submission";

		const options = {
			method: "POST",
			headers: {
				"content-type": "application/x-www-form-urlencoded; charset=UTF-8",
			},
			data: qs.stringify(formData),
			url:
				"https://api.akebiamedical.boldsky.dev/api/contact/send/medical",
		};
		axios(options)
			.then(function (response) {
				processPostSend(true);
			})
			.catch(function (response) {
				//handle error
				// console.log(response);
			});
	}

	function processPostSend(success) {
		if (success) {
			setDidSubmit(true);
		} else {
		}
	}

	function processFormSubmit(e) {
		e.preventDefault();
		e.stopPropagation();
		removeErrors();

		if (validateRequestForm()) {
			processSend();
		}
	}

	return (
		<div className="section section-half contact-form">
			<div className="content row">
				{didSubmit ? (
					<div id="submit-success" className="col">
						<h3>Form submitted.</h3>
						<p>
							Thank you for submitting your medical information request. We will
							contact you as soon as possible.
						</p>
					</div>
				) : (
					<div className="col">
						<h3>Akebia Medical Information Request Form</h3>
						<p className="required">Required fields*</p>
						<form id="form" method="post" onSubmit={e => processFormSubmit(e)}>
							<div className="table main row">
								<div className="col-md-4 col-12">
									<div className="form_element_required">
										<label htmlFor="form_first_name">First Name*</label>
										<input
											value={firstName}
											onChange={e => setFirstName(e.target.value)}
											onBlur={e => setFirstName(e.target.value)}
											id="form_first_name"
											name="form_first_name"
											type="text"
											placeholder="First Name"
											data-error="First name is required"
										/>
									</div>
									<div className="form_element_required">
										<label htmlFor="form_last_name">Last Name*</label>
										<input
											value={lastName}
											onChange={e => setLastName(e.target.value)}
											onBlur={e => setLastName(e.target.value)}
											id="form_last_name"
											type="text"
											name="form_last_name"
											placeholder="Last Name"
											data-error="Last name is required"
										/>
									</div>
									<label htmlFor="form_title">Title</label>
									<input
										value={title}
										onChange={e => setTitle(e.target.value)}
										onBlur={e => setTitle(e.target.value)}
										id="form_title"
										type="text"
										name="form_title"
										placeholder="Title"
									/>
									<p className="push_more">
										<strong>Degree*</strong>
									</p>
									<div
										id="form_degree"
										className="group_degree table form_element_required"
									>
										<div className="column">
											<div
												tabIndex={0}
												className={`checkbox ${degreeMD ? "checked" : ""}`}
												onKeyPress={e => setDegreeMD(!degreeMD)}
												onClick={e => setDegreeMD(!degreeMD)}
												role="checkbox"
												aria-checked={degreeMD}
											>
												<p>MD</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${degreeDO ? "checked" : ""}`}
												onKeyPress={e => setDegreeDO(!degreeDO)}
												onClick={e => setDegreeDO(!degreeDO)}
												role="checkbox"
												aria-checked={degreeDO}
											>
												<p>DO</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${degreeRD ? "checked" : ""}`}
												onKeyPress={e => setDegreeRD(!degreeRD)}
												onClick={e => setDegreeRD(!degreeRD)}
												role="checkbox"
												aria-checked={degreeRD}
											>
												<p>RD</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${degreeNP ? "checked" : ""}`}
												onKeyPress={e => setDegreeNP(!degreeNP)}
												onClick={e => setDegreeNP(!degreeNP)}
												role="checkbox"
												aria-checked={degreeNP}
											>
												<p>NP</p>
											</div>
										</div>
										<div className="column">
											<div
												tabIndex={0}
												className={`checkbox ${degreeRN ? "checked" : ""}`}
												onKeyPress={e => setDegreeRN(!degreeRN)}
												onClick={e => setDegreeRN(!degreeRN)}
												role="checkbox"
												aria-checked={degreeRN}
											>
												<p>RN</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${degreePharmD ? "checked" : ""}`}
												onKeyPress={e => setDegreePharmD(!degreePharmD)}
												onClick={e => setDegreePharmD(!degreePharmD)}
												role="checkbox"
												aria-checked={degreePharmD}
											>
												<p>PharmD</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${degreeRPh ? "checked" : ""}`}
												onKeyPress={e => setDegreeRPh(!degreeRPh)}
												onClick={e => setDegreeRPh(!degreeRPh)}
												role="checkbox"
												aria-checked={degreeRPh}
											>
												<p>RPh</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox hAuto ${degreeOther ? "checked" : ""
													}`}
												onKeyPress={e => setDegreeOther(degreeOtherValue !== "")}
												onClick={e => setDegreeOther(!degreeOther)}
												role="checkbox"
												aria-checked={degreeOther}
											>
												<p>Other</p>
												<input
													value={degreeOtherValue}
													onClick={e => e.stopPropagation()}
													onBlur={e => setDegreeOther(e.target.value !== "")}
													onChange={e => setDegreeOtherValue(e.target.value)}
													id="form_degree_other"
													type="text"
													name="form_degree_other"
												/>
											</div>
										</div>
										<div
											id="form_degree_error"
											data-error="Degree is required"
										></div>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="form_element_required">
										<label htmlFor="form_office">Institution/Office*</label>
										<input
											value={office}
											onChange={e => setOffice(e.target.value)}
											onBlur={e => setOffice(e.target.value)}
											id="form_office"
											type="text"
											name="form_office"
											placeholder="Institution/Office"
											data-error="Institution/Office is required"
										/>
									</div>
									<label htmlFor="form_address">Address</label>
									<input
										value={address}
										onChange={e => setAddress(e.target.value)}
										onBlur={e => setAddress(e.target.value)}
										id="form_address"
										type="text"
										name="form_address"
										placeholder="Address"
									/>
									<div className="group_city_state table">
										<div className="column">
											<label htmlFor="form_city">City</label>
											<input
												value={city}
												onChange={e => setCity(e.target.value)}
												onBlur={e => setCity(e.target.value)}
												id="form_city"
												type="text"
												name="form_city"
												placeholder="City"
											/>
										</div>
										<div className="column">
											<label htmlFor="form_state">State</label>
											<input
												value={state}
												onChange={e => setState(e.target.value)}
												onBlur={e => setState(e.target.value)}
												id="form_state"
												type="text"
												name="form_state"
												placeholder="State"
											/>
										</div>
									</div>
									<div className="group_zip_country table">
										<div id="zip_required_field" className={zipRequired}>
											<label id="form_zip_label" htmlFor="form_zip">{zipLabel}</label>
											<input
												value={zip}
												onChange={e => setZip(e.target.value)}
												onBlur={e => setZip(e.target.value)}
												id="form_zip"
												type="text"
												name="form_zip"
												placeholder="Zip"
												data-error="Zip is required in U.S."
											/>
										</div>
										<div className="column form_element_required">
											<label htmlFor="form_country">Country*</label>
											<input
												value={country}
												onChange={e => checkCountryZip(e)}
												onBlur={e => checkCountryZip(e)}
												id="form_country"
												type="text"
												name="form_country"
												placeholder="Country"
												data-error="Country is required"
											/>
										</div>
									</div>
									<div className="form_element_required">
										<label htmlFor="form_phone">Phone*</label>
										<input
											value={phone}
											onChange={e => setPhone(e.target.value)}
											onBlur={e => setPhone(e.target.value)}
											id="form_phone"
											type="text"
											name="form_phone"
											placeholder="Phone"
											data-error="Phone is required"
										/>
									</div>
									<div className="form_element_required">
										<label htmlFor="form_email">Email*</label>
										<input
											value={email}
											onChange={e => setEmail(e.target.value)}
											onBlur={e => setEmail(e.target.value)}
											id="form_email"
											type="text"
											name="form_email"
											placeholder="Email"
											data-error="A valid email is required"
										/>
									</div>
								</div>
								<div className="col-md-4 col-12">
									<div className="form_element_required">
										<label htmlFor="form_comments">
											Please provide your specific question(s)*
										</label>
										<textarea
											value={comments}
											onChange={e => setComments(e.target.value)}
											onBlur={e => setComments(e.target.value)}
											id="form_comments"
											name="form_comments"
											placeholder="Please provide your specific question(s)"
											data-error="Specific question(s) is required"
										></textarea>
									</div>
									<p className="push">
										<strong>Product*</strong>
									</p>
									<div id="form_product_group">
										<div id="form_product" className="group_product table form_element_required">
											<div className="column">
												<div
													tabIndex={0}
													className={`checkbox ${productAuryxia ? "checked" : ""
														}`}
													onKeyPress={e => setProductAuryxia(!productAuryxia)}
													onClick={e => setProductAuryxia(!productAuryxia)}
													role="checkbox"
													aria-checked={productAuryxia}
												>
													<p>Ferric Citrate (Auryxia)</p>
												</div>
											</div>
											<div className="column">
												<div
													tabIndex={0}
													className={`checkbox ${productVadadustat ? "checked" : ""
														}`}
													onKeyPress={e =>
														setProductVadadustat(!productVadadustat)
													}
													onClick={e =>
														setProductVadadustat(!productVadadustat)
													}
													role="checkbox"
													aria-checked={productVadadustat}
												>
													<p>Vadadustat (Vafseo)</p>
												</div>
											</div>
											<div
												tabIndex={0}
												className={`checkbox hanging ${productOther ? "checked" : ""
													}`}
												onKeyPress={e => setProductOther(productOtherValue !== "")}
												onClick={e => setProductOther(!productOther)}
												role="checkbox"
												aria-checked={productOther}
											>
												<p>Other</p>
												<input
													value={productOtherValue}
													onClick={e => e.stopPropagation()}
													onBlur={e => setProductOther(e.target.value !== "")}
													onChange={e => setProductOtherValue(e.target.value)}
													id="form_product_other"
													type="text"
													name="form_product_other"
												/>
											</div>
											<div
												id="form_product_error"
												data-error="Product is required"
											></div>
										</div>

									</div>
									<p className="push_more">
										<strong>Preferred delivery options:</strong>
									</p>
									<div id="form_delivery" className="group_delivery table">
										<div className="column">
											<div
												tabIndex={0}
												className={`checkbox ${deliveryEmail ? "checked" : ""}`}
												onKeyPress={e => setDeliveryEmail(!deliveryEmail)}
												onClick={e => setDeliveryEmail(!deliveryEmail)}
												role="checkbox"
												aria-checked={deliveryEmail}
											>
												<p>Email</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${deliveryFax ? "checked" : ""}`}
												onKeyPress={e => setDeliveryFax(!deliveryFax)}
												onClick={e => setDeliveryFax(!deliveryFax)}
												role="checkbox"
												aria-checked={deliveryFax}
											>
												<p>Fax</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${deliveryMail ? "checked" : ""}`}
												onKeyPress={e => setDeliveryMail(!deliveryMail)}
												onClick={e => setDeliveryMail(!deliveryMail)}
												role="checkbox"
												aria-checked={deliveryMail}
											>
												<p>Mail</p>
											</div>
										</div>
										<div className="column">
											<div
												tabIndex={0}
												className={`checkbox ${deliveryPhone ? "checked" : ""}`}
												onKeyPress={e => setDeliveryPhone(!deliveryPhone)}
												onClick={e => setDeliveryPhone(!deliveryPhone)}
												role="checkbox"
												aria-checked={deliveryPhone}
											>
												<p>Phone</p>
											</div>
											<div
												tabIndex={0}
												className={`checkbox ${deliveryMSL ? "checked" : ""}`}
												onKeyPress={e => setDeliveryMSL(!deliveryMSL)}
												onClick={e => setDeliveryMSL(!deliveryMSL)}
												role="checkbox"
												aria-checked={deliveryMSL}
											>
												<p>Field Medical Expert<br />Follow-Up</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="table attest">
								<div className="cell noborder">
									<div className="form_element_required check">
										<div
											id="form_attest"
											className={`checkbox hAuto ${attest ? "checked" : ""}`}
											onKeyPress={e => setAttest(!attest)}
											onClick={e => setAttest(!attest)}
											data-error="Certification is required"
											role="checkbox"
											aria-checked={attest}
											tabIndex={0}
										>
											<p>
												I certify that I am a healthcare professional, payer,
												formulary committee member, or other person involved in
												the provision or coverage of healthcare services and
												have made an unsolicited request for medical or
												scientific information regarding an Akebia
												investigational and/or marketed product(s).
											</p>
										</div>
									</div>
									<div className="form_element_required check">
										<div
											id="form_privacy"
											className={`checkbox hAuto ${privacy ? "checked" : ""}`}
											onKeyPress={e => setPrivacy(!privacy)}
											onClick={e => setPrivacy(!privacy)}
											data-error="Acknowledgment is required"
											role="checkbox"
											aria-checked={privacy}
											tabIndex={0}
										>
											<p>
												By clicking submit I understand that my information will
												be used by Akebia to respond to my unsolicited request
												for information. For more details on Akebia's privacy
												practices, please see our <a className="" href="https://akebia.com/privacy-policy/" target="_blank" rel="noreferrer">
													privacy policy
												</a>.
											</p>
										</div>
									</div>
								</div>
								<div className="cell noborder">
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</div>
							</div>
						</form>
					</div>
				)}
			</div>
			<div className="close"></div>
		</div>
	);
};

export default ContactForm;

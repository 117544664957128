import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Hero from "../components/hero";
import backdrop from "../images/header-image-05.png";
import ContactForm from "../components/contactForm";
import MSLLookup from "../components/mslLookup_v3";
import ManIcon from "../images/icon-man.inline.svg";
import FindIcon from "../images/icon-find.inline.svg";

function getQS() {
	const isBrowser = typeof window !== "undefined";
	if (!isBrowser) {
		return false;
	}
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let msl = params.get('msl');
	if (msl) {
		return false;
	} else {
		return true;
	}
}

const ContactUs = ({ location }) => {
	const getLocationState = () => {
		try {
			//console.log("Filter: " + location.state.filter);
			if (location.state.filter === 'msl') {
				//console.log("Contact: MSL");
				setIsContact(false);
			} else {
				//console.log("Contact: MIRF");
				setIsContact(true);
			}
		}
		catch (err) {
			//return true;
		}
	};
	const [isContact, setIsContact] = useState(true);
	const [hasSetHistory, setHasSetHistory] = useState(false);

	useEffect(
		() => {
			getLocationState();
		}
	);

	return (
		<Layout>
			<SEO title="Contact Us" />
			<Hero background={backdrop} large={true}>
				<div className="col-sm-6">
					<div className="card card-contact-us">
						<h1 className="card-contact-us-heading">Contact Us</h1>
						<p className="card-contact-us-body">
							Whether you're looking to schedule a 1:1 meeting or you have a
							general inquiry, we're committed to being there for you.
						</p>
						<h5 className="card-contact-us-subhead">
							Call Akebia Medical Information
						</h5>
						<p className="card-contact-us-phone">
							<a href="tel:+18444453799">1-844-445-3799</a>
						</p>
						<p className="card-contact-us-adverse">
							To report an adverse event or product complaint, select option 2
						</p>
					</div>
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad gray-bg">
				<div className="container breadcrumbs">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/contact-us"
									className="active"
								>
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div id="form" className="section section-half gray-bg">
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="contact-us-choose">
								<Link
									to="/contact-us"
									className={
										isContact ? "btn btn-toggle active" : "btn btn-toggle"
									}
									partiallyActive={true}
									state={{ filter: 'mirf' }}
								>
									<ManIcon />
									<div>
										Medical Information
										<br />
										Request
									</div>
								</Link>
								<Link
									to="/contact-us"
									className={
										!isContact ? "btn btn-toggle active" : "btn btn-toggle"
									}
									partiallyActive={true}
									state={{ filter: 'msl' }}
								>
									<FindIcon />
									<div>
										Connect with a
										<br />
										Field Medical Expert
									</div>
								</Link>
							</div>
							{isContact && <ContactForm />}
							{!isContact && <MSLLookup />}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ContactUs;
